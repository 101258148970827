import { css } from 'styled-components';

import theme from '../theme';

const timeline = () => css`
  background-image: linear-gradient(
    ${theme.colors.blueDark},
    ${theme.colors.blueDark}
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1px 100%;
`;

export default timeline;
