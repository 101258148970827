import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  // Reset
  * {
    border: 0;
    color: ${theme.colors.base};
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: ${theme.fontSizes.mouse};
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: ${theme.colors.blueLighter};
  }

  p,
  li {
    font-weight: 200;
  }

  p {
    line-height: 150%;
  }

  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
`;

export default GlobalStyle;
