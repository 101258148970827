import { SIcon } from './Icon.styles';

import IIcon from './Icon.interface';

const Icon: React.FC<IIcon> = ({ className, name }) => {
  return (
    <SIcon className={`material-symbols-outlined ${className}`}>
      {name}
    </SIcon>
  );
};

export default Icon;
