import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  hasScrolled: boolean;
  isOpen: boolean;
}

const initialState: AppState = {
  hasScrolled: false,
  isOpen: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHasScrolled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasScrolled = action.payload;
    },
    setIsOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpen = action.payload;
    }
  }
});

export const { setHasScrolled, setIsOpen } = appSlice.actions;

export default appSlice.reducer;
