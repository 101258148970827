import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SGreeting = styled.p`
  font-weight: 600;
  line-height: 1;
  margin-bottom: ${theme.lengths(3.5)};

  ${theme.orientations.forLandscape} {
    font-size: 15vh;

    ${theme.breakpoints.forTabletPortraitUp} {
      font-size: 14vh;
      margin-bottom: 4.5vh;
    }
  }

  ${theme.orientations.forPortrait} {
    font-size: 15vw;

    ${theme.breakpoints.forTabletPortraitUp} {
      font-size: 11.5vw;
      margin-bottom: 3.75vw;
    }
  }
`;

export const SIntroduction = styled.p`
  line-height: 140%;
  white-space: nowrap;
  z-index: 1;

  &,
  * {
    ${theme.orientations.forLandscape} {
      ${theme.breakpoints.forMobileOnly} {
        font-size: 6.5vh;
      }

      ${theme.breakpoints.forTabletPortraitUp} {
        font-size: 6vh;
      }
    }

    ${theme.orientations.forPortrait} {
      ${theme.breakpoints.forMobileOnly} {
        font-size: 6.5vw;
      }

      ${theme.breakpoints.forTabletPortraitUp} {
        font-size: 5vw;
      }
    }
  }
`;

export const SText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  transform: scale(1);

  ${theme.breakpoints.forMobileOnly} {
    animation: zoomMobile 2s;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    animation: zoom 2s;
    margin-top: 100px;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    margin-top: 125px;
    position: static;
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    margin-top: 164px;
  }

  @keyframes zoom {
    from {
      opacity: 0.25;
      transform: scale(0.95);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes zoomMobile {
    from {
      opacity: 0.25;
      transform: translate(-50%, -50%) scale(0.95);
    }

    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &,
  * {
    color: ${theme.colors.white};
  }
`;
