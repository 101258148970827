import { useSelector } from 'react-redux';

import { AppState } from '../../../store/appSlice';

import { SLabel, SLink, SMenuListItem } from './MenuListItem.styles';

import IMenuListItem from './MenuListItem.interface';

const MenuListItem: React.FC<IMenuListItem> = ({
  activeClass, name, offset, onClick: handleClick, setActiveClass, title
}) => {
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);
  const isActive = activeClass === name;

  const handleSetActive = (active: string) => {
    setActiveClass(active);
  };

  return (
    <SMenuListItem>
      <SLabel
        active={isActive.toString()}
        scrolled={hasScrolled.toString()}
      >
        <SLink
          offset={offset || window.innerWidth >= 900 ? -90 : -52}
          onClick={handleClick}
          onSetActive={handleSetActive}
          smooth
          spy
          to={name}
        >
          {title}
        </SLink>
      </SLabel>
    </SMenuListItem>
  );
};

export default MenuListItem;
