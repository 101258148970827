import { SDescription } from './Description.styles';

import IDescription from './Description.interface';

const Description: React.FC<IDescription> = ({ description }) => {
  return (
    <SDescription dangerouslySetInnerHTML={{ __html: description }} />
  );
};

export default Description;
