import { SImage } from './Image.styles';

import IImage from './Image.interface';

const Image: React.FC<IImage> = ({ alt, image }) => {
  return (
    <SImage
      alt={alt}
      src={'assets/' + image}
    />
  );
};

export default Image;
