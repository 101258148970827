import { SList } from './List.styles';

import IList from './List.interface';

const List: React.FC<IList> = ({ children }) => {
  return (
    <SList>
      {children}
    </SList>
  );
};

export default List;
