import { useTranslation } from 'react-i18next';

import { SGreeting, SIntroduction, SText } from './Text.styles';

const Text: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SText>
      <SGreeting>
        {t('hero.greeting')}
      </SGreeting>

      <SIntroduction>
        {/* First line */}
        {t('hero.introduction1')}
        <br />

        {/* Second line */}
        {t('hero.introduction2')}
        <strong>
          {t('hero.introduction3')}
        </strong>
        <br />

        {/* Third line */}
        {t('hero.introduction4')}
      </SIntroduction>
    </SText>
  );
};

export default Text;
