import { useDispatch, useSelector } from 'react-redux';

import { AppState, setIsOpen } from '../../../store/appSlice';

import { SMenuIcon } from './MenuIcon.styles';
import Icon from '../../atoms/Icon/Icon';

const MenuIcon: React.FC = () => {
  const dispatch = useDispatch();
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);
  const isOpen = useSelector((state: AppState) => state.isOpen);

  const handleClick = () => {
    dispatch(
      setIsOpen(!isOpen)
    );
  };

  return (
    <SMenuIcon
      hasScrolled={hasScrolled}
      isOpen={isOpen}
      onClick={handleClick}
    >
      <Icon name="menu" />
    </SMenuIcon>
  );
};

export default MenuIcon;
