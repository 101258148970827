import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setIsOpen } from '../../../store/appSlice';
import linkedIn from '../../../assets/linkedin.svg';

import { SButtons, SHero } from './Hero.styles';
import ButtonContact from '../../../components/atoms/ButtonContact/ButtonContact';
import Text from '../../../components/molecules/Text/Text';

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setIsOpen(false)
    );
  };

  return (
    <SHero
      id="start"
      onClick={handleClick}
    >
      <Text />

      <SButtons>
        <ButtonContact
          href="mailto:a.burburan@gmail.com"
          icon="alternate_email"
          title={t('common.email')}
        />

        <ButtonContact
          alt={t('common.linkedIn')}
          href="https://www.linkedin.com/pub/andreas-burburan/a2/a7/536"
          icon={linkedIn}
          rel="noreferrer"
          target="_blank"
          title={t('common.linkedIn')}
        />
      </SButtons>
    </SHero>
  );
};

export default Hero;
