import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SSection = styled.section`
  padding: 75px 5%;

  ${theme.breakpoints.forTabletPortraitUp} {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    padding: 100px 7.5%;
  }

  ${theme.breakpoints.forDesktopUp} {
    padding: 100px 20%;
  }

  &:nth-of-type(odd) {
    background-color: ${theme.colors.blueLight};

    h1 {
      outline: solid ${theme.lengths(4)} ${theme.colors.blueLight};
    }
  }

  &:nth-of-type(even) {
    background-color: ${theme.colors.blueLighter};

    h1 {
      outline: solid ${theme.lengths(4)} ${theme.colors.blueLighter};
    }
  }
`;
