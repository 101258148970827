import styled from 'styled-components';

import image from '../../../assets/andreas.jpg';
import theme from '../../../theme/theme';

export const SButtons = styled.div`
  bottom: ${theme.lengths(4)};
  position: absolute;

  ${theme.breakpoints.forMobileOnly} {
    text-align: center;
    width: 100%;
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    right: ${theme.lengths(5)};
  }

  ${theme.breakpoints.forDesktopUp} {
    bottom: ${theme.lengths(4)};
  }
`;

export const SHero = styled.section`
  background-image: url(${image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: calc(100vh - ${theme.lengths(1)});
  height: calc(100svh - ${theme.lengths(1)});

  &:before {
    background-color: ${theme.colors.blueDarker};
    content: '';
    display: block;
    height: calc(100vh - ${theme.lengths(1)});
    height: calc(100svh - ${theme.lengths(1)});
    opacity: 0.925;
    position: absolute;
    width: 100%;
  }
`;
