import styled from 'styled-components';

import theme from '../../../theme/theme';

import Section from '../../../components/templates/Section/Section';

export const SExperiences = styled(Section)`
  ${theme.breakpoints.forTabletLandscapeUp} {
    ${theme.timeline};
  }
`;
