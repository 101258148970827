import { useTranslation } from 'react-i18next';

import image from '../../../assets/andreas.jpg';

import { SBiography, SImage, SText } from './Biography.styles';
import Strengths from '../../../components/organisms/Strengths/Strengths';

const Biography: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="biography">
      <SBiography>
        <SImage
          alt={t('biography.imageAlt')}
          src={image}
          title={t('biography.imageTitle')}
        />

        <SText dangerouslySetInnerHTML={{ __html: t('biography.text') }} />
      </SBiography>

      <Strengths />
    </div>
  );
};

export default Biography;
