import { SSubtitle } from './Subtitle.styles';

import ISubtitle from './Subtitle.interface';

const Subtitle: React.FC<ISubtitle> = ({ text }) => {
  return (
    <SSubtitle>
      {text}
    </SSubtitle>
  );
};

export default Subtitle;
