import breakpoints from './variables/breakpoints';
import colors from './variables/colors';
import fontSizes from './variables/fontSizes';
import lengths from './variables/lengths';
import orientations from './variables/orientations';
import timeline from './mixins/timeline';

const theme = {
  breakpoints,
  colors,
  fontSizes,
  lengths,
  orientations,
  timeline
};

export default theme;
