import { SIcon, SText, STitle } from './Title.styles';

import ITitle from './Title.interface';

const Title: React.FC<ITitle> = ({ icon, text }) => {
  return (
    <STitle>
      <SIcon name={icon} />

      <SText dangerouslySetInnerHTML={{ __html: text }} />
    </STitle>
  );
};

export default Title;
