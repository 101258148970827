import { STime, SDash } from './Time.styles';

import ITime from './Time.interface';

const Time: React.FC<ITime> = ({
  isOngoing, timeEnd, timeStart
}) => {
  return (
    <STime>
      {timeStart}

      {(
        isOngoing ||
        timeEnd
      ) ? (
        <SDash>
          &ndash;
        </SDash>
      ) : null}

      {timeEnd}
    </STime>
  );
};

export default Time;
