import { SName } from './Name.styles';

import IName from './Name.interface';

const Name: React.FC<IName> = ({ name }) => {
  return (
    <SName dangerouslySetInnerHTML={{ __html: name }} />
  );
};

export default Name;
