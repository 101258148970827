import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SLink = styled.a`
  border-bottom: 1px solid ${theme.colors.white};
  color: inherit;
  font-size: inherit;
  margin-left: ${theme.lengths(1)};
  text-decoration: none;
`;

export const SStrength = styled.div`
  color: inherit;
  display: inline-block;
  font-size: inherit;
`;
