import { useTranslation } from 'react-i18next';

import List from '../../../components/molecules/List/List';
import Section from '../../../components/templates/Section/Section';
import Skill from '../../../components/molecules/Skill/Skill';
import Title from '../../../components/atoms/Title/Title';

import ISkill from '../../molecules/Skill/Skill.interface';

interface IOuter {
  icon: string;
  list: ISkill[];
  title: string;
}

const Skills: React.FC = () => {
  const { t } = useTranslation();

  const list: IOuter[] = t('cv.skills', { returnObjects: true });

  return (
    <div id="skills">
      {
        list &&
        Array.isArray(list) &&
        list.map((currentOuter: IOuter, indexOuter: number) => {
          return (
            <Section key={indexOuter}>
              <Title
                icon={currentOuter.icon}
                text={currentOuter.title}
              />

              <List>
                {currentOuter.list.map((currentInner: ISkill, indexInner: number) => {
                  return (
                    <Skill
                      key={indexInner}
                      level={currentInner.level}
                      skill={currentInner.skill}
                    />
                  );
                })}
              </List>
            </Section>
          );
        })
      }
    </div>
  );
};

export default Skills;
