import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SNavigation = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
  order: 1;
  user-select: none;

  ${theme.breakpoints.forTabletLandscapeUp} {
    flex: 1 1 auto;
    order: 0;
  }
`;
