import { useDispatch } from 'react-redux';

import { SSection } from './Section.styles';
import { setIsOpen } from '../../../store/appSlice';

import ISection from './Section.interface';

const Section: React.FC<ISection> = ({ children, className }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setIsOpen(false)
    );
  };

  return (
    <SSection
      className={className}
      onClick={handleClick}
    >
      {children}
    </SSection>
  );
};

export default Section;
