import { STimeline } from './Timeline.styles';
import Experience from '../../../components/molecules/Experience/Experience';

import ITimeline from './Timeline.interface';

const Timeline: React.FC<ITimeline> = ({ list }) => {
  return (
    <STimeline>
      {list.map((experience, index) => {
        return (
          <Experience
            description={experience.description}
            image={experience.image}
            imageAlt={experience.imageAlt}
            isOngoing={experience.isOngoing}
            key={index}
            name={experience.name}
            timeEnd={experience.timeEnd}
            timeStart={experience.timeStart}
          />
        );
      })}
    </STimeline>
  );
};

export default Timeline;
