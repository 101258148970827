import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SDescription = styled.p`
  font-size: ${theme.fontSizes.mouse};
  font-style: italic;

  a {
    color: ${theme.colors.blueLight};
    word-break: break-all;
  }
`;
