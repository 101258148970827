import ListItem from '../../../components/atoms/ListItem/ListItem';
import Progress from '../../../components/atoms/Progress/Progress';

import ISkill from './Skill.interface';

const Skill: React.FC<ISkill> = ({ level, skill }) => {
  return (
    <ListItem>
      {skill}

      <Progress level={level} />
    </ListItem>
  );
};

export default Skill;
