import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SList = styled.ul`
  list-style-type: none;

  ${theme.breakpoints.forTabletPortraitUp} {
    column-count: 2;
    column-gap: ${theme.lengths(5)};
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    column-gap: ${theme.lengths(7.5)};
  }

  ${theme.breakpoints.forDesktopUp} {
    column-count: 3;
    column-gap: ${theme.lengths(10)};
  }
`;
