import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SDash = styled.span`
  margin: 0 ${theme.lengths(0.25)};
`;

export const STime = styled.p`
  font-size: ${theme.fontSizes.mouse};
  margin-bottom: ${theme.lengths(1)};
`;
