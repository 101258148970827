import styled from 'styled-components';

import theme from '../../../theme/theme';

interface IMenuList {
  hasScrolled: boolean;
  isOpen: boolean;
}

export const SMenuList = styled.ul<IMenuList>`
  background-color: ${theme.colors.blueDark};
  height: 100vh;
  height: 100svh;
  left: -100vw;
  list-style-type: none;
  overflow-y: scroll;
  padding-left: 6vw;
  padding-top: 7vw;
  position: absolute;
  top: 100px;
  transition: left 0.25s;
  width: 90vw;

  ${theme.breakpoints.forTabletPortraitUp} {
    padding-left: 5vw;
    width: 40vw;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    background-color: transparent;
    display: flex;
    height: auto;
    justify-content: flex-end;
    left: 0;
    overflow-y: initial;
    padding: 0 ${theme.lengths(6)} 0 0;
    position: static;
    top: auto;
    width: auto;
  }

  ${theme.breakpoints.forDesktopUp} {
    padding: 0 ${theme.lengths(8)} 0 0;
  }

  ${({ hasScrolled }) => hasScrolled && `
    top: 52px;
  `}

  ${({ isOpen }) => isOpen && `
    left: 0;
  `}
`;
