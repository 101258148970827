import { SExperience } from './Experience.styles';
import Description from '../../../components/atoms/Description/Description';
import Image from '../../../components/atoms/Image/Image';
import Name from '../../../components/atoms/Name/Name';
import Time from '../../../components/atoms/Time/Time';

import IExperience from './Experience.interface';

const Experience: React.FC<IExperience> = ({
  description, image, imageAlt, isOngoing, name, timeEnd, timeStart
}) => {
  return (
    <SExperience>
      <Time
        isOngoing={isOngoing}
        timeEnd={timeEnd}
        timeStart={timeStart}
      />

      {(
        image &&
        imageAlt
      ) ? (
        <Image
          alt={imageAlt}
          image={image}
        />
      ) : null}

      <Name name={name} />

      {description ? (
        <Description description={description} />
      ) : null}
    </SExperience>
  );
};

export default Experience;
