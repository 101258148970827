import { useTranslation } from 'react-i18next';

import langEn from '../../../assets/lang-en.svg';
import langSv from '../../../assets/lang-sv.svg';

import { SLanguages } from './Languages.styles';
import ButtonLanguage from '../../atoms/ButtonLanguage/ButtonLanguage';

const Languages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SLanguages>
      <ButtonLanguage
        alt={t('header.svenskaAlt')}
        href="/"
        image={langSv}
        title={t('header.svenska')}
      />

      <ButtonLanguage
        alt={t('header.englishAlt')}
        href="/en"
        image={langEn}
        title={t('header.english')}
      />
    </SLanguages>
  );
};

export default Languages;
