import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SListItem = styled.li`
  break-inside: avoid;
  color: ${theme.colors.white};
  display: grid;
  font-size: ${theme.fontSizes.cat};

  &:not(:last-child) {
    padding-bottom: ${theme.lengths(4)};
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    padding-bottom: ${theme.lengths(4)};
  }
`;
