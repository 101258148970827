import styled from 'styled-components';

import theme from '../../../theme/theme';
import quote from '../../../assets/quote.svg';

import Section from '../../../components/templates/Section/Section';

export const SBiography = styled(Section)`
  display: flex;
  justify-content: center;
`;

export const SImage = styled.img`
  border: 10px solid ${theme.colors.blueLighter};
  transform: scaleX(-1);

  ${theme.breakpoints.forMobileOnly} {
    display: none;
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    float: left;
    height: 175px;
    margin-right: ${theme.lengths(3.5)};
  }
`;

export const SText = styled.p`
  background-image: url(${quote});
  background-repeat: no-repeat;
  color: ${theme.colors.white};
  font-style: italic;
  max-width: 800px;

  ${theme.breakpoints.forMobileOnly} {
    background-position: 75% 35%;
    background-size: 125px;
    font-size: ${theme.fontSizes.cat};
    text-align: center;
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    background-position: 75% ${theme.lengths(5)};
    background-size: 100px;
    font-size: ${theme.fontSizes.lion};
    text-align: left;
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    justify-content: center;
  }
`;
