import styled from 'styled-components';

export const SButton = styled.a`
  align-items: center;
  background-clip: padding-box;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 100%;
  text-decoration: none;
  vertical-align: middle;
`;
