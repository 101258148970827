import styled from 'styled-components';

import theme from '../../../theme/theme';

interface IMenuIcon {
  hasScrolled: boolean;
  isOpen: boolean;
}

export const SMenuIcon = styled.span<IMenuIcon>`
  color: ${theme.colors.white};
  display: inline-flex;
  font-size: ${theme.lengths(4)};
  line-height: 100%;
  position: relative;
  transition: font-size 0.25s, width 0.25s, transform 0.25s;
  width: ${theme.lengths(4)};

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: none;
  }

  ${({ hasScrolled }) => hasScrolled && `
    font-size: ${theme.lengths(3)};
    width: ${theme.lengths(3)};
  `}

  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
`;
