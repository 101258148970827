import styled from 'styled-components';

import theme from '../../../theme/theme';

export const STimeline = styled.ul`
  ${theme.timeline};
  list-style-type: none;
  margin: 0 auto;

  ${theme.breakpoints.forTabletLandscapeUp} {
    background: transparent;
  }

  ${theme.breakpoints.forDesktopUp} {
    left: -5%;
    position: relative;
    width: 110%;
  }

  ${theme.breakpoints.forDesktopLargeUp} {
    max-width: 1000px;
    position: static;
    width: 100%;
  }
`;
