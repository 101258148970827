import { SListItem } from './ListItem.styles';

import IListItem from './ListItem.interface';

const ListItem: React.FC<IListItem> = ({ children }) => {
  return (
    <SListItem>
      {children}
    </SListItem>
  );
};

export default ListItem;
