import { SNavigation } from './Navigation.styles';
import MenuIcon from '../../atoms/MenuIcon/MenuIcon';
import MenuList from '../../molecules/MenuList/MenuList';
import Overlay from '../../molecules/Overlay/Overlay';

const Navigation: React.FC = () => {
  return (
    <SNavigation>
      <MenuIcon />

      <Overlay />

      <MenuList />
    </SNavigation>
  );
};

export default Navigation;
