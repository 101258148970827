import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SName = styled.h3`
  &,
  * {
    color: ${theme.colors.blueLight};
    font-size: ${theme.fontSizes.dog};
    font-weight: 300;
    line-height: 125%;
    margin: ${theme.lengths(0.5)} 0 ${theme.lengths(1)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
