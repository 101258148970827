import { useTranslation } from 'react-i18next';

import List from '../../molecules/List/List';
import ListItem from '../../atoms/ListItem/ListItem';
import Section from '../../templates/Section/Section';
import Title from '../../atoms/Title/Title';

import IListItem from '../../atoms/ListItem/ListItem.interface';

const Hobbies: React.FC = () => {
  const { t } = useTranslation();

  const list: IListItem[] = t('cv.hobbies.list', { returnObjects: true });

  return (
    <Section>
      <Title
        icon={t('cv.hobbies.icon')}
        text={t('cv.hobbies.title')}
      />

      <List>
        {
          list &&
          Array.isArray(list) &&
          list.map((hobby: IListItem, index: number) => {
            return (
              <ListItem key={index}>
                {hobby as React.ReactNode}
              </ListItem>
            );
          })
        }
      </List>
    </Section>
  );
};

export default Hobbies;
