import { SButton } from './Button.styles';

import IButton from './Button.interface';

const Button: React.FC<IButton> = ({
  children, className, href, onClick: handleClick, rel, target, title
}) => {
  return (
    <SButton
      className={className}
      href={href}
      onClick={handleClick}
      rel={rel}
      target={target}
      title={title}
    >
      {children}
    </SButton>
  );
};

export default Button;
