import { useTranslation } from 'react-i18next';

import { SLink, SStrength } from './Strengths.styles';
import List from '../../molecules/List/List';
import ListItem from '../../atoms/ListItem/ListItem';
import Section from '../../../components/templates/Section/Section';
import Title from '../../../components/atoms/Title/Title';

interface IStrength {
  strength: string;
  url: string;
}

const Strengths: React.FC = () => {
  const { t } = useTranslation();

  const list: IStrength[] = t('cv.strengths.list', { returnObjects: true });

  return (
    <Section>
      <Title
        icon="fitness_center"
        text={t('cv.strengths.title')}
      />

      <List>
        {
          list &&
          Array.isArray(list) &&
          list.map((strength: IStrength, index: number) => {
            return (
              <ListItem key={index}>
                <SStrength>
                  {index + 1}.

                  <SLink
                    href={strength.url}
                    target="_blank"
                  >
                    {strength.strength}
                  </SLink>
                </SStrength>
              </ListItem>
            );
          })
        }
      </List>
    </Section>
  );
};

export default Strengths;
