import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SImage = styled.img`
  height: auto;
  margin: ${theme.lengths(1.5)} 0;
  max-height: ${theme.lengths(7)};
  max-width: ${theme.lengths(9)};
  pointer-events: none;
  width: auto;
`;
