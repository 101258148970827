import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppState } from '../../../store/appSlice';
import image from '../../../assets/logo.svg';

import { SImage, SLink } from './Logo.styles';

const Logo: React.FC = () => {
  const { t } = useTranslation();

  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);

  return (
    <SLink href="#start">
      <SImage
        alt={t('header.logoAlt')}
        hasScrolled={hasScrolled}
        src={image}
        title={t('header.start')}
      />
    </SLink>
  );
};

export default Logo;
