import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppState, setIsOpen } from '../../../store/appSlice';

import { SMenuList } from './MenuList.styles';
import MenuListItem from '../../atoms/MenuListItem/MenuListItem';

const MenuList: React.FC = () => {
  const { t } = useTranslation();

  const [activeClass, setActiveClass] = useState('');

  const dispatch = useDispatch();
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);
  const isOpen = useSelector((state: AppState) => state.isOpen);

  useEffect(() => {
    if(isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'initial';
    }
  }, [isOpen])

  const handleClick = () => {
    dispatch(
      setIsOpen(false)
    );
  };

  return (
    <SMenuList
      hasScrolled={hasScrolled}
      isOpen={isOpen}
    >
      <MenuListItem
        activeClass={activeClass}
        name="start"
        onClick={handleClick}
        setActiveClass={setActiveClass}
        title={t('header.start')}
      />

      <MenuListItem
        activeClass={activeClass}
        name="biography"
        onClick={handleClick}
        setActiveClass={setActiveClass}
        title={t('header.biography')}
      />

      <MenuListItem
        activeClass={activeClass}
        name="experiences"
        onClick={handleClick}
        setActiveClass={setActiveClass}
        title={t('header.experiences')}
      />

      <MenuListItem
        activeClass={activeClass}
        name="skills"
        onClick={handleClick}
        setActiveClass={setActiveClass}
        title={t('header.skills')}
      />

      <MenuListItem
        activeClass={activeClass}
        name="contact"
        offset={window.innerHeight}
        onClick={handleClick}
        setActiveClass={setActiveClass}
        title={t('header.contact')}
      />
    </SMenuList>
  );
};

export default MenuList;
