import styled from 'styled-components';

import theme from '../../../theme/theme';

interface IProgress {
  level: number;
}

export const SProgress = styled.span<IProgress>`
  background-color: ${theme.colors.white};
  border-radius: ${theme.lengths(1)};
  display: block;
  height: ${theme.lengths(2)};
  margin-top: ${theme.lengths(1.5)};
  max-width: 500px;

  &:before {
    background-color: ${theme.colors.blueDarker};
    border-radius: ${theme.lengths(1)};
    content: '';
    display: block;
    height: 100%;
  }

  ${({ level }) => level && `
    &:before {
      width: ${level}%;
    }
  `}
`;
