import { useTranslation } from 'react-i18next';

import linkedIn from '../../../assets/linkedin.svg';

import { SCta, SFooter, SLure } from './Footer.styles';
import ButtonContact from '../../atoms/ButtonContact/ButtonContact';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SFooter>
      <SLure id="contact" />

      <SCta>
        {t('footer.cta')}
      </SCta>

      <ButtonContact
        href="mailto:a.burburan@gmail.com"
        icon="alternate_email"
        title={t('common.email')}
      />

      <ButtonContact
        alt={t('common.linkedIn')}
        href="https://www.linkedin.com/pub/andreas-burburan/a2/a7/536"
        icon={linkedIn}
        rel="noreferrer"
        target="_blank"
        title={t('common.linkedIn')}
      />
    </SFooter>
  );
};

export default Footer;
