import { useSelector } from 'react-redux';

import { AppState } from '../../../store/appSlice';

import { SButtonLanguage, SFlag } from './ButtonLanguage.styles';

import IButtonLanguage from './ButtonLanguage.interface';

const ButtonLanguage: React.FC<IButtonLanguage> = ({
  alt, href, image, title
}) => {
  const hasScrolled = useSelector((state: AppState) => state.hasScrolled);

  return (
    <SButtonLanguage href={href}>
      <SFlag
        alt={alt}
        hasScrolled={hasScrolled}
        src={image}
        title={title}
      />
    </SButtonLanguage>
  );
};

export default ButtonLanguage;
