import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SExperience = styled.li`
  background-color: ${theme.colors.white};
  border-bottom: ${theme.lengths(1)} solid ${theme.colors.blueLightest};
  box-sizing: border-box;
  margin-bottom: ${theme.lengths(2.5)};
  padding: ${theme.lengths(3)};

  ${theme.breakpoints.forTabletLandscapeUp} {
    border-bottom-width: 10px;
    margin-bottom: -${theme.lengths(5)};
    padding-left: ${theme.lengths(4)};
    padding-right: ${theme.lengths(4)};
    position: relative;
    width: calc(50% - ${theme.lengths(6)});
    z-index: 1;
  }

  &:before {
    ${theme.breakpoints.forTabletLandscapeUp} {
      background-color: ${theme.colors.blueDark};
      border-radius: 50%;
      content: '';
      height: 9px;
      position: absolute;
      top: -5px;
      width: 9px;
    }
  }

  &:after {
    ${theme.breakpoints.forTabletLandscapeUp} {
      background-color: ${theme.colors.white};
      content: '';
      height: ${theme.lengths(6)};
      position: absolute;
      top: 0;
      width: ${theme.lengths(6)};
      z-index: -1;
    }
  }

  &:nth-child(odd) {
    &:before {
      right: -${theme.lengths(6)};
      transform: translateX(4.5px);
    }

    &:after {
      right: 0;
      transform: skew(-40deg);
    }
  }

  &:nth-child(even) {
    ${theme.breakpoints.forTabletLandscapeUp} {
      margin-left: auto;
    }

    &:before {
      left: -${theme.lengths(6)};
      transform: translateX(-4.5px);
    }

    &:after {
      left: 0;
      transform: skew(40deg);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
