import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cvEn from './localization/en/cv';
import cvSv from './localization/sv/cv';
import generalEn from './localization/en/general';
import generalSv from './localization/sv/general';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          ...{ cv: cvEn },
          ...generalEn
        }
      },
      sv: {
        translation: {
          ...{ cv: cvSv },
          ...generalSv
        }
      }
    },
    lng: 'sv',
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
