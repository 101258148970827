import { SButtonContact, SIcon, SImage } from './ButtonContact.styles';

import IButtonContact from './ButtonContact.interface';

const ButtonContact: React.FC<IButtonContact> = ({
  alt, href, icon, rel, target, title
}) => {
  return (
    <SButtonContact
      href={href}
      rel={rel}
      target={target}
      title={title}
    >
      {alt ? (
        <SImage
          alt={alt}
          src={icon}
        />
      ) : (
        <SIcon name={icon} />
      )}
    </SButtonContact>
  );
};

export default ButtonContact;
