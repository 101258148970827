import styled from 'styled-components';

import theme from '../../../theme/theme';

import Section from '../../templates/Section/Section';

export const SCta = styled.p`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.dog};
  margin-bottom: ${theme.lengths(3)};
  max-width: 630px;

  ${theme.breakpoints.forTabletPortraitUp} {
    font-size: ${theme.fontSizes.lion};
  }

  ${theme.breakpoints.forTabletPortraitUp} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SFooter = styled(Section)`
  padding-bottom: 175px;
  position: relative;
  text-align: center;
`;

export const SLure = styled.div`
  bottom: 0;
  height: calc(100vh - 52px);
  height: calc(100svh - 52px);
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;

  ${theme.breakpoints.forTabletLandscapeUp} {
    height: calc(100vh - 90px);
  }
`;
