import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { SExperiences } from './Experiences.styles';
import Subtitle from '../../../components/atoms/Subtitle/Subtitle';
import Timeline from '../../../components/organisms/Timeline/Timeline';
import Title from '../../../components/atoms/Title/Title';

import IExperience from '../../molecules/Experience/Experience.interface';

interface IOuter {
  icon: string;
  list: IInner[];
  title: string;
}

interface IInner {
  list: IExperience[];
  title: string;
}

const Experiences: React.FC = () => {
  const { t } = useTranslation();

  const list: IOuter[] = t('cv.experiences', { returnObjects: true });

  return (
    <div id="experiences">
      {
        list &&
        Array.isArray(list) &&
        list.map((currentOuter: IOuter, indexOuter: number) => {
          return (
            <SExperiences key={indexOuter}>
              <Title
                icon={currentOuter.icon}
                text={currentOuter.title}
              />

              {currentOuter.list.map((currentInner: IInner, indexInner: number) => {
                return (
                  <Fragment key={indexInner}>
                    <Subtitle text={currentInner.title} />

                    <Timeline list={currentInner.list} />
                  </Fragment>
                );
              })}
            </SExperiences>
          );
        })
      }
    </div>
  );
};

export default Experiences;
