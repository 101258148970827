import styled from 'styled-components';

import theme from '../../../theme/theme';

import Icon from '../../atoms/Icon/Icon';

interface IIcon {
  isOpen: boolean;
}

interface IOverlay {
  hasScrolled: boolean;
  isOpen: boolean;
}

export const SIcon = styled(Icon)<IIcon>`
  color: ${theme.colors.white};
  content: 'x';
  cursor: pointer;
  display: none;
  font-size: ${theme.fontSizes.whale};
  position: absolute;
  right: ${theme.lengths(4)};
  top: ${theme.lengths(2)};

  ${({ isOpen }) => isOpen && `
    ${theme.breakpoints.forTabletPortraitUp} {
      display: inline;
    }
  `}
`;

export const SOverlay = styled.div<IOverlay>`
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 100px;
  width: 100vw;
  z-index: -1;

  // Background
  &:before {
    background-color: rgba(0, 0, 0, 0);
    content: '';
    height: 100vh;
    height: 100svh;
    position: absolute;
    width: 100vw;
    transition: background-color 0.25s;
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: none;
  }

  ${({ hasScrolled }) => hasScrolled && `
    top: 52px;
  `}

  ${({ isOpen }) => isOpen && `
    &:before {
      background-color: rgba(0, 0, 0, 0.5);
    }
  `}
`;
