import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

import GlobalStyle from './theme/GlobalStyle';

import Biography from './components/organisms/Biography/Biography';
import Experiences from './components/organisms/Experiences/Experiences';
import Filler from './components/atoms/Filler/Filler';
import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import Hero from './components/organisms/Hero/Hero';
import Hobbies from './components/organisms/Hobbies/Hobbies';
import Skills from './components/organisms/Skills/Skills';

const App = () => {
  const { t } = useTranslation();
  const [, setTimestamp] = useState(new Date());

  const skills: object[] = t('cv.skills', { returnObjects: true });

  useEffect(() => {
    const language = window.location.pathname.substring(1);

    if(
      i18n.options.resources &&
      language &&
      Object.keys(i18n.options.resources).includes(language)
    ) {
      i18n.changeLanguage(language);

      setTimestamp(new Date());
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="author" content={t('head.author')} />
        <meta name="description" content={t('head.description')} />
        <meta name="keywords" content={t('head.keywords')} />
        <title>{t('head.title')}</title>
      </Helmet>

      <GlobalStyle />

      <Header />

      <Hero />

      <Biography />

      <Experiences />

      <Skills />

      {skills.length % 0 ? (
        <Filler />
      ) : (
        null
      )}

      <Hobbies />

      <Footer />
    </HelmetProvider>
  );
};

export default App;
