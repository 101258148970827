import styled from 'styled-components';

import theme from '../../../theme/theme';

export const SLanguages = styled.div`
  flex: 1;
  order: 3;
  text-align: right;

  ${theme.breakpoints.forTabletLandscapeUp} {
    flex: 0 1 auto;
    order: 0;
    text-align: left;
  }
`;
