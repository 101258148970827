import styled from 'styled-components';

import theme from '../../../theme/theme';

import Icon from '../../atoms/Icon/Icon';

export const SIcon = styled(Icon)`
  display: block;
  font-size: ${theme.lengths(8)};
  margin-bottom: ${theme.lengths(2)};

  ${theme.breakpoints.forTabletPortraitUp} {
    font-size: ${theme.lengths(7.5)};
  }

  ${theme.breakpoints.forTabletLandscapeUp} {
    display: inline-block;
    margin-bottom: 0;
    margin-right: ${theme.lengths(1.5)};
  }
`;

export const STitle = styled.h1`
  background-color: inherit;
  color: ${theme.colors.white};
  display: inline-block;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: ${theme.lengths(6)};

  ${theme.breakpoints.forTabletLandscapeUp} {
    align-items: center;
    display: inline-flex;
    margin-bottom: ${theme.lengths(7)};
  }
`;

export const SText = styled.span`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.elephant};
  word-break: break-word;

  ${theme.breakpoints.forTabletPortraitUp} {
    font-size: ${theme.fontSizes.whale};
  }

  sup {
    color: inherit;
    font-size: 50%;
    margin-left: ${theme.lengths(0.25)};
  }
`;
